<template>
    <div class="chat-message">
        {{ user }}: {{ content }}
    </div>
</template>
<script>
export default {
    name: "ChatMessage",
    props: ["user","content"]
}
</script>
<style scoped>
.chat-message {
    display: flex;
    padding: 2px;
    align-items: center;
    border: 1px solid black;
    height: 10px;
}
</style>
<template>
    <div>
       <div>
            <Movie v-for="movie in movies" 
                :key="movie.mov_id"
                :id="movie.mov_id" 
                :name="movie.mov_name"
                :description="movie.mov_desc" 
                :year="movie.mov_year" 
                :director="movie.mov_director"></Movie>
       </div>
    </div>
</template>
<script>
import Movie from '../components/Movie.vue'
import axios from 'axios';

export default {
    name: 'MoviesView',
    components: { Movie },
    data: function() {
        return {
            movies: []
        }
    },
    mounted() {
        axios.get("http://ita.saleiv.com/2019/api/movies")
            .then((res) => {
                this.movies = res.data.data;
            })
    }
}
</script>
<template>
    <div class="movie">
        <div class="movie-header-container">
            <h3>{{ name }}</h3>
        </div>
        <div class="movie-content-container">
            <div class="movie-glance-info">{{ year }} - {{ director }}</div>
            <div class="movie-description">{{  description }}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Movie',
    props: ["id","name","description","year","director"]
}
</script>
<style scoped>
.movie {
    display: flex;
    flex-direction: column;
}

.movie-header-container {
    display: flex;
    justify-content: center;
}

.movie-content-container {
    display: flex;
    flex-direction: column;
    text-align: start;
}

</style>
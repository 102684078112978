<template>
    <div>
        <Chat></Chat>
    </div>
</template>
<script>
import Chat from '@/components/Chat.vue'

export default {
    components: {
        Chat
    },
    name: 'ChatView'
}
</script>
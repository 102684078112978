<template>
    <div>
        <h3>Chat</h3>
        <chat-message v-for="message in filteredMessages" :user="message.ime" :content="message.sadrzaj"></chat-message>
    </div>
</template>
<script>
    import axios from 'axios';
    import ChatMessage from './ChatMessage.vue'

    export default {
        name: "Chat",
        components: {
            ChatMessage
        },
        data: function(){
            return {
                messages: []
            }
        },
        computed: {
            filteredMessages() {
                let filtered = [];
                for(var i=0; i<this.messages.length; i++) {
                    if(this.messages[i].ime !== null && this.messages[i].sadrzaj !==null) {
                        filtered.push(this.messages[i]);
                    }
                }
                return filtered;
            }
        },
        mounted() {
            axios.get("http://ita.saleiv.com/2023/api/chat")
                .then((res) => {
                    this.messages = res.data.messages;
                })
        }
    }
</script>